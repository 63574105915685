.button {
     
cursor: pointer;
text-align: center;

 /* padding: 10px 56px; */
 padding: 10px 20px;

 text-decoration: none;
 border: none;
  font-size: var(--font12);
  border-radius: var(--borderLg);
  background-color:var( --mainWhite);
  border: 1px solid var(--brand);
color: var(--brand);

  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
  /* background-color:var( --brand); */
  /* background-color: #666; */
  background-color: #eceff1 ;
  color: var(--mainWhite);


  }
  
  .button--inverse {
    background: var(--brand);
    color: var(--mainWhite);
    border: 1px solid var(--brand);
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: var(--brand);
    border: 1px solid var(--brand);
    background: var(--mainWhite);


  }
  
  .button--danger {
    background: #830000;
    border-color: #830000;
    color: var(--mainWhite);
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .button--form{
    height: 50px;
  }



  @media (max-width: 550px) {
    
    .button--form{
      height: 40px;
    }
  
  }
   