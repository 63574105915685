 
.Cards{
  background-color: var(--mainWhite);
  color: var(--mainBlack);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: var(--borderLg);
  z-index: 0;
  display: flex;
  align-items: center;
  padding: 14px;
  width: 100%;
  margin: 0 auto;
 


}
.pieChartItem{
  position: absolute;
  top: 50%;
  left: 50%;  

  transform: translate(-50%, -50%);

}

.storageItem{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 3rem 3rem;
    margin-top: 50px;
    justify-content: center;
    align-items: center;

}
.cardText{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item {
    border-radius: var(--borderLg);
    padding: 1rem;
    background: var(--mainWhite);
   
    column-gap: 1rem;
    display: grid;
    /* grid-template-columns: auto 1fr; */
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    row-gap: 2rem;
    font-size: 14px;



}

span {
    width: 3rem;
    height: 3rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
  }
  .icon {
    font-size: 1.6rem;
  }


 
  .cardValue{
    /* margin-bottom: 0; */
    letter-spacing: 0;
    font-weight: 900;
    font-size: 30px;

  }
  .cardValue1{
    /* margin-bottom: 0; */
    letter-spacing: 0;
    color: #394855;
    font-size: 12px;
    margin-top:2%;

  }

  .cardValue1{
    justify-self: center;
    font-weight: 500;
  }



  @media (max-width: 550px) {
     
    
  
    .cardValue{
      
      font-size: 20px;
  
    }
   
  
  }


 