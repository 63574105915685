 
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500:0,600:0,700;0,900;1,300&family=Rubik:wght@300;400;500;600;700;800;900&display=swap'); */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @font-face {
    font-family: "Open Sans Semibold";
    src: url("../src/fonts/OpenSans-Semibold.ttf") format("truetype");
}
  body {
  
  
    font-family: 'Open Sans Semibold', sans-serif;

  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    max-width: 100vw;
    background-color: #eceff1;
  }
  
  
  
  
   
  
  
  :root {
    --mainWhite: white;
    --mainBlack: #001f3f;
  
    --white: var(--mainWhite);
    --black: var(--mainBlack);
  
    --background: var(--brand);;
    --card: #00acde!important;
    --red: #ab0303;
    --disabledText: #a1a9b0;
   
    --brand: #0079ab;
    --brandHover: #00acde!important;


    --borderColor:#ddd;
     
  
  
    --borderLg: 5px;
    /* --borderButton:5px; */
  
     
  
    --font44: 44px;
    --font40: 40px;
    --font39: 39px;
    --font38: 38px;
    --font37: 37px;
    --font36: 36px;
    --font35: 35px;
    --font34: 34px;
    --font33: 33px;
    --font32: 32px;
    --font31: 31px;
    --font30: 30px;
    --font29: 29px;
    --font28: 28px;
    --font27: 27px;
    --font26: 26px;
    --font25: 25px;
    --font24: 24px;
    --font23: 23px;
    --font22: 22px;
    --font21: 21px;
    --font20: 20px;
    --font18: 18px;
    --font16: 16px;
    --font15: 15px;
    --font14: 14px;
    --font14: 14px;
    --font13: 13px;
    --font12: 12px;
    --font11: 11px;
    --font10: 10px;
    --font9: 9px;
    --font8: 8px;
    --font7: 7px;
    --font6: 6px;
    --font5: 5px;
  }
   
  
  .font-800 {
    font-size: var(--font44);
    font-weight: 600;
    opacity: 1 !important;
    margin-bottom: 20px;
  }
  
  .font-700 {
    font-size: var(--font36);
  }
  .font-600 {
    font-size: var(--font24);
  }
  
  .font-500 {
    font-size: var(--font20);
    line-height: 20px;
  }
  
  .font-400 {
    font-size: var(--font18);
  }
  
  .font-300 {
    font-size: var(--font16);
  }
  .font-200 {
    font-size: var(--font14);
  }
  .font-100 {
    font-size: var(--font12);
  }
  .font-000 {
    font-size: var(--font11);
  }
  
  h2 {
    font-size: var(--font24);
    margin-bottom: 15px;
    display: block;
    text-align: center;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  h1,
  h3 {
    font-size: var(--font36);
    margin-bottom: 15px;
    display: block;
    text-align: center;
    font-weight: 400;
    padding-top: 20px;
  }
  
 
  .display-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  }
  
  
 
  .smallContainerprimary{
    width: 100%;
    margin: 50px auto;
  }
  
  .copy-container {
    position: fixed;
    top: 0%;
    left: 0%;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    align-items: center;
    opacity: 0;
    pointer-events: none;
  }
  
  .copy-container.active {
    opacity: 1;
    pointer-events: all;
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 1;
  
  }
  .copy-popup {
    background: var(--mainBlack) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 12px;
    transition: transform 0.5s ease;
    transform: translateY(-2rem);
    min-width: 30%;
    min-height: 30vh;
  }
  
  .copy-popup.active {
    background: var(--card) !important;
  
    transform: translateY(0rem);
  }
   
  
  
  button {
    /* padding: 10px 56px; */
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    font-size: var(--font16);
    border-radius: var(--borderLg);
  }
   
  button:hover {
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  button:disabled {
    background: var(--disabledText);
    cursor: not-allowed;
  }
  button:disabled:hover {
    background: var(--disabledText);
    cursor: not-allowed;
  }
  
  
  
  .hero-container {
    width: 95%;
    margin: 0 auto;
  }
  
  
  .homePageContainer {
    width: 94%;
    margin: 0 0 0 6%;
  }
  .homePageMainContainer{
    max-width: 1120px;
    margin: 0 auto;
  }
  
  .main-container {
   width: 88%;
   margin: 0 auto;
  }
  
  .pink {
    background: #ffe0f0;
    color: #da4a91;
  }
  .green {
    background: hsl(186, 100%, 94%);
    color: hsl(185, 62%, 45%);;
  }
  .purple {
    background: #e6e6ff;
    color: #5d55fa;
  }
  .yellow {
    background: #fffbea;
    color: #f0b429;
  }
  
  /* media Queeries */
  @media (max-width: 850px) {
    .font-800 {
      font-size: var(--font24);
    }
    .font-700 {
      font-size: var(--font22);
    }
    .font-600 {
      font-size: var(--font20);
    }
  
    .font-500 {
      font-size: var(--font16);
    }
  
    .font-400 {
      font-size: var(--font14);
    }
  
    .font-300 {
      font-size: var(--font13);
    }
    .font-200 {
      font-size: var(--font10);
    }
    .hero-container {
      width: 100%;
    }
    
  }
  @media (max-width: 700px) {
    .font-800 {
      font-size: var(--font20);
    }
    .font-700 {
      font-size: var(--font16);
    }
    .font-600 {
      font-size: var(--font14);
    }
  
    .font-500 {
      font-size: var(--font13);
    }
  }
  /* media Queeries */
  @media (max-width: 600px) {
    .font-800 {
      font-size: var(--font24);
    }
    .font-700 {
      font-size: var(--font22);
    }
    .font-600 {
      font-size: var(--font20);
    }
  
    .font-500 {
      font-size: var(--font16);
    }
  
    .font-400 {
      font-size: var(--font14);
    }
  
    .font-300 {
      font-size: var(--font13);
    }
    .font-200 {
      font-size: var(--font10);
    }
  
   
  }
  
  .nodataAvailable{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   margin-top: 50px;
  
  }
  
  
  input:disabled{
    cursor: not-allowed;
  }
  
  
  
 
  
    
  
  
  
  
  @media (max-width: 550px) {
     
    
  
    .main-container {
      width: 94%;
     }
     
   
  
  }
  
  