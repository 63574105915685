.projects_details {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 20px;
	background-color: var(--mainWhite);
	border-bottom: 1px solid var(--borderColor);
	font-weight: 600;
	font-size: 16px !important;
}

.projects_detail {
	display: flex;
	padding: 10px 20px;
	border-bottom: 1px solid var(--borderColor);
	font-size: 12px;
	font-weight: 500;
}
.project_info__action,
.projects_info__name,
.project_info__created,
.projects_info__order_number,
.project_info__name {
	flex: 1;
}

.projects_detail {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.project_info__action {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.project_info__action button,
.project_info__action a {
	padding: 5px 14px;
}

.project_notFound {
	padding: 30px;
	text-align: center;
	font-size: 30px;
}
.project_info__created a {
	text-decoration: none;
	color: var(--mainBlack);
	font-weight: 500;
	font-size: 12px;
}

.subOrgName {
	color: var(--brand) !important;
}

.subOrgName a {
	color: var(--brand) !important;
}

@media (max-width: 850px) {
	.projects_info__order_number,
	.projects_info__name,
	.project_info__name {
		display: none;
	}
	.project_info__action button,
	.project_info__action a {
	}

	.project_info__created {
		max-width: 50%;
		overflow: scroll;
	}
}
